.App {
  text-align: center;
}

body {
  padding-top: 75px;
  padding-bottom: 75px;
}

body::-webkit-scrollbar {
  width: 0.25rem;
}

body::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.25);
}

body::-webkit-scrollbar-thumb {
  background: #007bff;
}

@media only screen and (max-width: 800px) {
  body {
    font-size: 0.9em;
  }
}

/**
* Overwriting the Bootstrap container for larger screens
*/
.container {
  width: 100%;
  padding-left: 50px;
  padding-right: 50px;
  max-width: 1300px;
}

@media only screen and (max-width: 800px) {
  .container {
    padding-left: 5px;
    padding-right: 5px;
  }
}
