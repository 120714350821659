.container-slides {
  background-color: white;
  margin-left: 10%;
  margin-right: 10%;
  height: 100%;
  text-align: left;
}
.slide {
  width: 100%;
  height: 100%;
  height: calc(100% - 30px);
  height: -moz-calc(100% - 30px);
  height: -webkit-calc(100% - 30px);
  overflow-y: auto;
  overflow-x: hidden;
}

.slide::-webkit-scrollbar {
  width: 0.25rem;
}

.slide::-webkit-scrollbar-track {
  background: rgba(0, 0, 0, 0.25);
}

.slide::-webkit-scrollbar-thumb {
  background: #007bff;
}

.slide p {
  margin-block-start: 0;
  margin-block-end: 5px;
}
.slide-header {
  background-color: #50b6ff;
  color: white;
  font-weight: bold;
  border-radius: 4px;
  width: 100%;
  padding: 20px;
  margin-block-end: 0;
  text-align: center;
  z-index: 5;
}

.slide:not(:first-child) {
  display: none;
}

.slide-image {
  margin-right: 10px;
  float: right;
}

.slide-image img {
  height: 150px;
  width: 150px;

  /*Trims image into a circle */
  border-radius: 300px;
}

.slide-text {
  font-weight: bold;
}

.slide ul {
  padding-inline-start: 25px;
  margin-block-end: 25px;
}

.slide ul li {
  text-align: left;
}

.slide h5,
.slide p.slide-text {
  padding-left: 7px;
  padding-right: 3px;
}

/**
* For responsiveness purposes
*/

@media only screen and (max-width: 800px) {
  .slide-image img {
    height: 100px;
    width: 100px;
  }
  .slide-header {
    padding: 6px;
  }
}

@media only screen and (max-width: 600px) {
  .slide-image img {
    display: none;
  }
}
