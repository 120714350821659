canvas {
  position: absolute;
  top: 15%;
}

.slide-chart {
  position: relative;
  height: 100%;
  text-align: center;
}

/**
* To get the radar chart on PDF, we must have rendered the chart.
* When taking the radar chart which is responsive, it will take it
* as it is meaning that if it is small, it would print a small and badly 
* formatted radar chart. This forces us render a hidden radar chart with
* ideal formatting such that it transfer well. Only used for PDF purposes
*/
.hidden-and-only-used-for-printing-purposes {
  width: 1200px;
  height: 800px;
  visibility: hidden;
  position: fixed;

  /**This is for mobile users and that the web  page does not expand 
  * awkwardly 
  */
  overflow: hidden;
}

/**
* For responsiveness purposes
* Note: Small screens will be show the bar graph, and larger screen
*       the radar chart
* Also note that regardless of the screen format, the radar chart will be
* displayed on PDF
*/

.radar-graph {
  visibility: visible;
}

.bar-graph {
  visibility: hidden;
  width: 100%;
  height: 80%;
}

@media only screen and (max-width: 780px) {
  .radar-graph {
    visibility: hidden;
  }

  .bar-graph {
    visibility: visible;
  }
}

/**
* Hide chart slide text when screen size is too small
*/
@media only screen and (max-height: 600px) {
  .chart-text {
    display: none;
  }
  canvas {
    position: absolute;
    top: 10%;
  }
}

@media only screen and (max-width: 370px) {
  .chart-text {
    display: none;
  }
  canvas {
    position: absolute;
    top: 10%;
  }
}
