/**
* Stlying for the footer
*/
footer {
  text-align: center;
  background: #f8f8f8;
  mix-blend-mode: normal;
  height: 70px;
  box-shadow: 0px -4px 6px rgba(0, 0, 0, 0.25);
  position: fixed;
  width: 100%;
  left: 0px;
  bottom: -1px;
  font-size: 12px;
  box-sizing: content-box;
  z-index: 6;
}

/**
* For responsive purposes
*/
@media only screen and (max-width: 800px) {
  footer {
    font-size: 10px;
    height: 60px;
  }
}
@media only screen and (max-width: 600px) {
  footer {
    font-size: 8px;
    height: 50px;
  }
}

@media only screen and (max-width: 400px) {
  footer {
    font-size: 6px;
    height: 40px;
  }
}
