.bottom-space {
  margin-bottom: 100px;
}

.dimension-header-img {
  width: 80%;
  margin-left: 10%;
  margin-right: 10%;
  margin-block-end: 20px;
  margin-block-start: 15px;
  height: auto;
  max-width: 744px;
}

.dimension-descriptors {
  text-align: left;
  padding-left: 25px;
  padding-right: 15px;
}

@media only screen and (max-width: 800px) {
  .dimension-header-img {
    width: 100%;
    margin-left: 0;
    margin-right: 0;
    margin-block-start: 0px;
  }
}
