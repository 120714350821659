.header {
  background: #f8f8f8;
  mix-blend-mode: normal;
  height: 70px;
  box-shadow: 0px 6px 4px rgba(0, 0, 0, 0.25);
  width: 100%;
  padding: 0px;
  position: absolute;
  border-bottom: 1px solid #cccccc;
  position: fixed;
  top: -1px;
}

.concordia-logo {
  position: absolute;
  padding: 2px;
  float: left;
  height: 100%;
  width: auto;
}

@media only screen and (max-width: 800px) {
  .header {
    height: 50px;
  }
  .concordia-logo {
    height: 120%;
    width: auto;
  }
}
