.progress-bar {
  position: relative;
  height: 30px;
  width: 100%;

  border: 1px solid;
  background: #ebebeb;
  box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
}

.filler {
  background-color: #56b8ff;
  height: 100%;
  width: 0%;
  border-radius: inherit;
  box-shadow: 2px 0px 4px rgba(0, 0, 0, 0.25);
  transition: width 0.3s ease-in-out;
  text-align: left;
}

.filler-content {
  left: 3px;
  bottom: 2px;
  position: absolute;
  font-size: 14px;
}
