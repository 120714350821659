/* Slideshow container */
.slideshow-container {
  position: relative;
  margin: auto;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  background-color: #f8f8f8;
  height: 600px;
  max-width: 100%;
  margin-top: 15px;
}

@media only screen and (max-height: 650px) {
  .slideshow-container {
    height: 500px;
  }
}

@media only screen and (max-height: 600px) {
  .slideshow-container {
    height: 350px;
  }
}

.slideshow-container div .fa {
  height: 100%;
  position: relative;
  top: 45%;
}

/* Next & previous buttons */
.slideshow-container .prev,
.slideshow-container .next {
  cursor: pointer;
  position: absolute;
  min-width: 10%;
  font-weight: bold;
  font-size: 18px;
  transition: 0.6s ease-out;
  user-select: none;
  height: 100%;
  top: 0;
}

/* Position the "next button" to the right */
.slideshow-container .next {
  right: 0;
}

/* Position the "prev button" to the left */
.slideshow-container .prev {
  left: 0;
}

/* On hover, add a black background color with a little bit see-through */
.slideshow-container .prev:hover,
.slideshow-container .next:hover {
  background-color: rgba(0, 0, 0, 0.2);
  color: white;
}

/* The dots/bullets/indicators */
.slideshow-container .dot {
  cursor: pointer;
  height: 10px;
  width: 10px;
  margin: 0 2px;
  background-color: white;
  border-radius: 50%;
  display: inline-block;
  transition: background-color 0.6s ease;
  border: 1px solid;
  border-color: #50b6ff;
}

.slideshow-container .dot:hover,
.slideshow-container .dot.active {
  background-color: #50b6ff;
}

.dot-container {
  width: 80%;
  background-color: white;
  margin-left: 10%;
  height: 30px;
  position: relative;
  top: -30px;
  z-index: 5;
}
