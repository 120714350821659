/**
* For responsive purposes
*/
.responsive-input {
  text-align: left;
  margin: 5px;
}
.responsive-tr {
  text-align: left;
}

/**
* Mobile
*/
tbody > tr.responsive-tr:nth-last-of-type(1) > td {
  border-bottom: 1px solid #b8b8b8;
}

tbody > tr.responsive-tr:nth-of-type(1) > th:nth-of-type(1) {
  border-top: 0;
}

tbody > tr.responsive-tr:only-of-type > th {
  border-top: 1px solid #b8b8b8;
}

tbody > tr.responsive-tr > td {
  border-left: 1px solid #b8b8b8;
  border-right: 1px solid #b8b8b8;
  border-top: 1px solid #b8b8b8;
  border-bottom: 0;
  padding: 5px 0;
}

tbody > tr.responsive-tr > th {
  border-left: 1px solid #b8b8b8;
  border-right: 1px solid #b8b8b8;
  border-top: 0;
  border-bottom: 0;
}

tbody:first-of-type > tr.responsive-tr:first-of-type > th.responsive-th {
  border-top: 1px solid #b8b8b8;
}
