.imageForNotFound {
  float: right;
}

.pageNotFound {
  max-width: 1000px;
}

.framework-design-01 {
  width: 45%;
  height: auto;
  max-width: 192px;
  max-height: 360px;
}

.framework-design-02 {
  width: 45%;
  height: auto;
  margin-bottom: 10px;
}

.framework-design-03 {
  width: 45%;
  height: auto;
  margin-bottom: 10px;
}

.framework-design-04 {
  width: 55%;
  height: auto;
  max-width: 205px;
  max-height: 360px;
}

/**
* For responsive purposes
*/
@media only screen and (max-width: 800px) {
  .framework-design-02 {
    display: none;
  }
  .framework-design-03 {
    width: 100%;
  }
}
