:root {
  font-size: 16px;
}

body,
h1,
h2,
h3,
h4,
h5 {
  font-family: Arial, Helvetica, sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
