/* The Modal (background) */
.modalBackground {
  position: fixed; /* Stay in place */
  z-index: 1031; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
}

/* Modal Content/Box */
.modalContent {
  background-color: white;
  position: relative;
  top: 35%;
  margin: auto;
  width: 80%;
  max-width: 1000px;
  border-radius: 20px 0 20px 0;
}

/* The Close Button */
.modalClose {
  color: #aaa;
  float: right;
  height: 25px;
  width: 25px;
}

.modalClose:hover,
.modalClose:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

/* Modal Header */
.modalHeader {
  border-radius: 17px 0 0 0;
  background-color: #f8f8f8;
  color: black;
  min-height: 40px;
  padding-top: 5px;
}

.modalHeaderText {
  margin-block-end: 0;
  margin-block-start: 0;
  margin-left: 25px;
}

/* Modal Body */
.modalBody {
  padding: 5px;
  border-bottom: 1px solid black;
  border-top: 1px solid black;
  font-size: 16px;
}

/* Modal Footer */
.modalFooter {
  padding: 2px 16px;
  background-color: #f8f8f8;
  color: black;
  border-radius: 0 0 17px 0;
  min-height: 35px;
}

.modalFooter button {
  margin: 5px;
}
