/**
* Styling for the nav bar
*/
.topNav {
  width: 100%;
  overflow: hidden;
  display: block;
}

.topNav div {
  height: 60px;
  float: right;
  text-align: center;
  font-size: 1.4em;
  color: black;
  font-weight: bolder;
  padding: 10px 16px;
  line-height: 40px;
  background-color: white;
  border-radius: 10px;
  margin: 5px 2px 5px 10px;
  border: 1px solid black;
}

.topNav div:hover {
  animation-name: navItemAnimation;
  animation-duration: 0.5s;
  cursor: pointer;
  color: white;
  background-color: #007bff;
}
.topNav a:hover {
  text-decoration: none;
}
.topNav div:active {
  background-color: #0062cc;
}

@keyframes navItemAnimation {
  0% {
    background-color: white;
    color: black;
  }
  100% {
    background-color: #007bff;
    color: white;
  }
}

/**
* Only purpose is for the navbar items to start under the header
*/
.topNav div.empty {
  visibility: hidden;
  padding: 0;
  margin: 0;
}

/**
* For responsive purposes, the navbar collaspes
*/

.topNav .icon {
  display: none;
  padding: 0;
  width: 50px;
  border-radius: 0;
  margin: 0;
  border: 0;
  background-color: inherit;
}
.topNav .icon i {
  height: 100%;
  width: 100%;
  padding: 7px 0px 0px 2px;
}

@media screen and (max-width: 800px) {
  .topNav.responsive {
    position: relative;
  }

  .topNav.responsive .icon {
    position: absolute;
    right: 0;
    top: 0;
  }
  .topNav.responsive div:not(.icon) {
    float: none;
    display: block;
    text-align: left;
    background: white;
    border: 0;
    border-bottom: 1px solid #000000;
    opacity: 1;
    border-radius: 0;
    margin: 0;
  }
  .topNav.responsive div:hover {
    background-color: #007bff;
    animation: none;
  }

  .topNav div {
    display: none;
    height: 50px;
  }

  .topNav div.icon {
    float: right;
    display: block;
  }
  #navItems {
    padding-left: 15px;
    padding-top: 5px;
  }
}

/**
* For dynamic navbar items which only show on their pages
* I.e. on "/exit" only display navbar item "Home"
*/
.navItem {
  display: none;
}
