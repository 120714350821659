.diagram {
  width: 100%;
  padding: 0 10px 10px 10px;
  cursor: pointer;
}
.diagram:hover {
  box-shadow: 5px 5px 15px 0px #888888;
}
.fullscreen-img-backgroud {
  position: fixed; /* Stay in place */
  z-index: 1031; /* Sit on top */
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.9);
}
.fullscreen-img {
  max-width: 900px;
  max-height: 900px;
  margin: auto;
  padding: 0;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  top: 0;
}
.fullscreen-img:hover {
  box-shadow: none;
}
.side-text {
  width: 50%;
  margin: auto;
  text-align: left;
}
.side-img {
  width: 50%;
}
.in-img .diagram {
  padding-bottom: 16px;
}
.survey-button-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
}
.login-statement {
  text-align: center;
  margin-block-start: 10px;
}
.survey-button {
  flex: 1;
  width: fit-content;
  margin-right: 10px;
}
.readmore {
  margin-top: 10px;
}
