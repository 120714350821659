.card {
  margin-bottom: 50px;
  padding: 10px;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.radio-buttons {
  display: flex;
  flex-direction: column;
}

.fa-exclamation-circle {
  color: #aa2b45;
}
