/**
* General table styling
*/
table {
  position: relative;
  border-collapse: separate;
  border-spacing: 0px;
  margin: 1rem;
}

td {
  font-weight: normal;
}

th {
  text-align: left;
  font-weight: normal;
  padding: 10px;
}

td {
  border: 1px solid #b8b8b8;
  padding: 5px 0;
}

/* 
  First cell of table is empty
*/
thead > tr:nth-of-type(1) th:nth-of-type(1) {
  border-right: 1px solid #b8b8b8;
  background-color: transparent;
}

/*
  By allowing sticky headers, the borders of the 
  table have to be separately managed
*/
tbody > tr > td:nth-last-of-type(1) {
  border-right: 2px solid #b8b8b8;
}

tbody > tr:nth-last-of-type(1) > td {
  border-bottom: 2px solid #b8b8b8;
}

tbody > tr:nth-of-type(1) > th:nth-of-type(1) {
  border-top: 2px solid #b8b8b8;
}

.question-block {
  border-top: 1px solid #b8b8b8;
  border-bottom: 1px solid #b8b8b8;
  border-left: 2px solid #b8b8b8;
  border-right: 1px solid #b8b8b8;
}

tbody > tr:nth-last-of-type(1) > .question-block {
  border-bottom: 2px solid #b8b8b8;
}

thead > tr > .table-headers {
  border-right: 1px solid #b8b8b8;
  border-left: 1px solid #b8b8b8;
}

thead > tr > .table-headers:nth-last-of-type(1) {
  border-right: 2px solid #b8b8b8;
}

thead > tr > .table-headers:nth-last-of-type(5) {
  border-left: 1px solid #b8b8b8;
}

/**
* Alternating row's color
*/
tr:nth-child(even) {
  background-color: #e6f7ff;
}

tr:nth-child(even) > td:hover {
  background-color: #d6f3ff;
}

tr:nth-child(odd) > td:hover {
  background-color: #ebebeb;
}

/**
* Fixed header
*/
.table-headers {
  text-align: center;
  font-weight: bold;
  border-top: 2px solid #b8b8b8;
  border-bottom: 1px solid #b8b8b8;
  white-space: nowrap;
}

thead th {
  position: sticky;
  position: -webkit-sticky;
  top: 70px;
  background: white;
}
